import * as React from "react";

import Seo from "../components/seo";
import styled from "styled-components";
import { Link } from "gatsby";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const NotFoundPage = () => (
  <>
    <link
      rel="stylesheet"
      href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
      crossOrigin="true"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap"
      rel="stylesheet"
    />
    <Seo title="404: Not found" />
    <Wrapper>
      <h1>404: Not Found</h1>
      <Link to="/">Head back home</Link>
    </Wrapper>
  </>
);

export default NotFoundPage;
